import React, {useRef, useState} from "react";
import {
    convertToFloorplanCardData,
    DriveGallery,
    FloorplansSection,
    PropertyLocation,
    sortFloorplans,
    TeamSection,
    trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {AmenitiesSection} from "../components/amenities/AmenitiesSection";
import {ApartmentHeroSection} from "../components/hero/ApartmentHeroSection";
import {ApartmentsFeature} from "../components/feature/ApartmentsFeature";
import {
    FloorplanSectionSkeleton
} from "@renaissancerentals/renaissance-component/lib/floorplan/section/FloorplanSectionSkeleton";
import {ApartmentSecondaryFeature} from "../components/feature/ApartmentSecondaryFeature";
import {PageProps} from "./Home";
import {MainLayout} from "../components/layout/MainLayout";
import {propertyId} from "../services/DataService";

export const Apartments: React.FC<PageProps> = ({property, isLoading, contact}) => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorPlanRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    const handleRefToFloorPlan = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };


    return (<MainLayout handleRefToFloorPlan={handleRefToFloorPlan} isHomePage={false}
                        handleRefToMap={handleRefToMap} isMainPage={true}
                        showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                        setShowApplicationModal={setShowApplicationModal}
                        setShowContactModal={setShowContactModal}
                        contact={contact} property={property}
        >
            <ApartmentHeroSection/>
            {isLoading ? "" :
                <ApartmentsFeature handleRefToFloorPlan={handleRefToFloorPlan}
                                   featuredFloorplans={property.floorplans.filter(floorplan => floorplan.featured)}/>}

            <ApartmentSecondaryFeature
                handleRefToApply={() => setShowApplicationModal(true)}
                handleRefToContact={() => {
                    setShowContactModal(true);
                    trackContactClicked("verona-park");
                }}
                handleRefToFloorPlan={handleRefToFloorPlan}
            />
            <div className="container">
                {isLoading ? "" :
                    <DriveGallery
                        driveId={property.photosFolderId}
                        type="simple"
                        showName={false}
                        initialSize={15}
                        propertyId={propertyId}
                    />}
            </div>
            <AmenitiesSection heading="Amenities"/>


            <div ref={floorPlanRef} id="floorplan" className="reference">
            </div>
            {isLoading ?
                <FloorplanSectionSkeleton/> :
                <>{property?.floorplans ? <FloorplansSection
                    propertyId={propertyId}
                    floorplans={
                        sortFloorplans(
                            property.floorplans.filter(floorplan => floorplan.active)
                                .map(floorplan => convertToFloorplanCardData(floorplan)
                                ), "featured")
                    }/> : ""}

                </>
            }
            <div ref={mapRef} id="location" className="reference">
            </div>
            <div className="container">
                <h2 className="heading"><span className="emphasized">Location</span></h2>
            </div>
            <PropertyLocation property={property} isLoading={isLoading}
                              handleRefToContact={() => {
                                  setShowContactModal(true);
                                  trackContactClicked("verona-park");
                              }}/>

            <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={propertyId}/>

        </MainLayout>
    );
};
