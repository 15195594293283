import React from "react";
import {Button} from "@contentmunch/muncher-ui";
import {FloorplanCardSlider, FloorplanDetails} from "@renaissancerentals/renaissance-component";
import "./assets/ApartmentsFeature.scss";
import {propertyId} from "../../services/DataService";

export const ApartmentsFeature: React.FC<ApartmentsFeatureProps> = (
    {featuredFloorplans, handleRefToFloorPlan}) => {
    return (
        <div className="container">
            <section className="section-apartments-feature">

                <div className={featuredFloorplans.length > 0 ? "two-columns" : "one-column"}>
                    <div className="column-left">
                        <h3>an unmatched living experience</h3>
                        <p>Traditional European-inspired architecture combined with exceptionally designed interiors
                            give our Apartments and TownHomes the perfect balance of Old World authenticity
                            and modern and modern day amenities. Our residences and neighborhoods exude an unmatched
                            elegance in the heart of Bloomington’s southeast side. Join us at Verona Park for a
                            thoughtfully designed living experience unlike any other.</p>
                        <Button variant="primary" onClick={handleRefToFloorPlan}>View Floorplans »</Button>
                    </div>
                    <div className="column-right">
                        <FloorplanCardSlider floorplans={featuredFloorplans} propertyId={propertyId}/>
                    </div>
                </div>
            </section>
        </div>
    );
};

export interface ApartmentsFeatureProps {
    featuredFloorplans: FloorplanDetails[];

    handleRefToFloorPlan: () => void;
}