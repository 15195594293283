import React from "react";
import "./assets/TertiaryFeature.scss";


export const TertiaryFeature: React.FC<TertiaryFeatureProps> = (
    {handleRefToContact, handleRefToMap}) => {
    return (
        <section className="section-tertiary-feature">
            <div className="feature feature--first">
                <div className="feature-text">
                    <h3><i>Take a</i></h3>
                    <h4>Virtual Tour</h4>
                </div>
            </div>
            <div className="feature feature--second">
                <div className="feature-text">
                    <h3><i>View our</i></h3>
                    <h4><a onClick={handleRefToMap} href="#location">Locations</a></h4>
                </div>
            </div>
            <div className="feature feature--third">
                <div className="feature-text">
                    <h3><i>Questions?</i></h3>
                    <h4><a onClick={handleRefToContact} href="#contact">Contact Us</a></h4>
                </div>
            </div>
        </section>
    );
};

export interface TertiaryFeatureProps {

    handleRefToMap: () => void;
    handleRefToContact: () => void;
}