import React from "react";
import "./assets/SecondaryFeature.scss";


export const SecondaryFeature: React.FC = () => {
    return (
        <section className="section-secondary-feature">
            <div className="container">
                <h2><i>A neighborhood inspired by tradition & refined by design</i></h2>
                <p>Located on Bloomington’s southeast side, the Verona Park Neighborhood is a village-like residential
                    community with walking trails, waterscapes, inviting gathering spaces & more. European-inspired
                    architecture is displayed throughout the neighborhood while our various residences feature
                    exceptionally designed interiors that include real antiquities as well as an unparalleled attention
                    to detail. Join us at Verona Park for a perfect balance of Old World charm and Modern-day
                    amenities.</p>
            </div>
        </section>
    );
};