import React from "react";
import {Helmet} from "react-helmet-async";

export const Houses: React.FC = () => {
    return (
        <main>
            <Helmet>
                <title>Single Family Houses & Home Lots For Sale</title>
            </Helmet>
            <section className="div-full--center">
                <h2>Coming Soon!</h2>
            </section>
        </main>
    );
};