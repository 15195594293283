import React from 'react';
import './assets/Amenities.scss';

export const AmenitiesSection: React.FC<AmenitiesSectionProps> = ({heading}) => {

    return (
        <section className="section-amenities">
            <div className="container">
                <h2>{heading}</h2>
                <div className="section-amenities--content">
                    <div>
                        <h3>Our Community</h3>
                        <ul>
                            <li>Outdoor gas fireplace</li>
                            <li>Links to Creeks Edge Trail system</li>
                            <li>Outdoor gas grill</li>
                            <li>Community Plaza with fountain water feature</li>
                            <li>Free off-street parking</li>
                            <li>On-site recycling</li>
                            <li>Smithville Gigabit Internet available</li>
                            <li>Community lounge areas & conference rooms</li>
                            <li>Binford/Rogers Elementary</li>
                            <li>Jackson Creek Middle School</li>
                            <li>Bloomington High School South</li>
                            <li>24 Hour emergency maintenance service</li>
                            <li>Managed by <a href="https://www.renaissancerentals.com">Renaissance Rentals</a>,
                                Bloomington's most trusted local landlord
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3>Our Residences</h3>
                        <ul>
                            <li>Timeless and inviting aparments and homes</li>
                            <li>Classic, European-inspired finishes</li>
                            <li>Brand new & Available now</li>
                            <li>Stainless Steel appliances</li>
                            <li>HE Washer & dryer included</li>
                            <li>Endless hot water supply</li>
                            <li>Private balconies</li>
                            <li>Controlled-access interior corridor entrances</li>
                            <li>Spacious closets</li>
                            <li>Large, airy windows</li>
                            <li>Open-concept layouts</li>

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export interface AmenitiesSectionProps {
    heading: string;
}