import React, {useState} from "react";
import {
    convertToFloorplanCardData,
    FloorplanSectionSkeleton,
    FloorplansSection,
    sortFloorplans
} from "@renaissancerentals/renaissance-component";
import "./assets/Home.scss";
import {MainLayout} from "../components/layout/MainLayout";
import {propertyId} from "../services/DataService";
import {PageProps} from "./Home";

export const Estelle: React.FC<PageProps> = ({property, isLoading, contact}) => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorplansToInclude = ["merci", "merci-grand", "lumiere", "vivant", "amour", "chambre"];


    return (
        <MainLayout isHomePage={false}
                    isMainPage={false}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    setShowApplicationModal={setShowApplicationModal}
                    setShowContactModal={setShowContactModal}
                    contact={contact} property={property}
        >

            {isLoading ?
                <FloorplanSectionSkeleton/> :
                <>{property?.floorplans ? <FloorplansSection
                    propertyId={propertyId}
                    floorplans={
                        sortFloorplans(
                            property.floorplans
                                .filter(floorplan => floorplan.active)
                                .filter(floorplan => floorplansToInclude.includes(floorplan.id))
                                .map(floorplan => convertToFloorplanCardData(floorplan)
                                ), "featured")
                    }/> : ""}

                </>
            }

        </MainLayout>
    );
};
