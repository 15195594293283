import React from "react";
import "./assets/ApartmentSecondayFeature.scss";


export const ApartmentSecondaryFeature: React.FC<ApartmentSecondaryFeatureProps> = (
    {handleRefToContact, handleRefToApply, handleRefToFloorPlan}) => {
    return (
        <section className="section-apartment-secondary-feature">
            <h4>Our top-rated</h4>
            <h2>Rental Collections</h2>
            <div className="container">
                <div className="feature-body">
                    <div className="feature feature--first">
                        <div className="feature-text">
                            <h4>penthouse</h4>
                            <h4>& corner view</h4>
                        </div>
                    </div>
                    <div className="feature feature--second">
                        <div className="feature-text">
                            <h4>dog friendly</h4>
                        </div>
                    </div>
                    <div className="feature feature--third">
                        <div className="feature-text">
                            <h4>private</h4>
                            <h4>garages</h4>
                        </div>
                    </div>
                    <div className="feature feature--fourth">
                        <div className="feature-text">
                            <h4>antiquities</h4>
                        </div>
                    </div>
                </div>
                <div className="div-two-column">
                    <div className="first-column">
                        <div>Find Your Perfect Apartment</div>
                        <div>Schedule a Consultation</div>
                        <div>Apply Online</div>
                    </div>
                    <div className="second-column">
                        <div><a href="#floorplan" onClick={handleRefToFloorPlan}>search floorplans »</a></div>
                        <div><a href="#contact" onClick={handleRefToContact}>let our team guide you »</a></div>
                        <div><a href="#apply" onClick={handleRefToApply}>apply for free »</a></div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export interface ApartmentSecondaryFeatureProps {

    handleRefToApply: () => void;
    handleRefToContact: () => void;
    handleRefToFloorPlan: () => void;
}