import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Home} from "./pages/Home";
import {NotFound} from "./pages/NotFound";
import HttpsRedirect from "react-https-redirect";
import {HelmetProvider} from 'react-helmet-async';
import "./assets/App.scss";
import {Apartments} from "./pages/Apartments";
import {Houses} from "./pages/Houses";
import {Contact, getProperty, PropertyDetails} from "@renaissancerentals/renaissance-component";
import {defaultContact} from "@renaissancerentals/renaissance-component/lib/contact/data/Contact";
import {FloorplanPage} from "./pages/FloorplanPage";
import {Estelle} from "./pages/Estelle";

const App: React.FC = () => {
    const [property, setProperty] = useState<PropertyDetails>({} as PropertyDetails);
    const [isLoading, setIsLoading] = useState(true);
    const [contact, setContact] = useState<Contact>(defaultContact);
    useEffect(() => {
        getProperty("verona-park").then(data => {
            setProperty(data);
            setContact({
                phone: data.phone,
                email: data.email,
                address: data.address + ", " + data.zipcode
            })

        }).finally(() => setIsLoading(false));
    }, []);
    return (
        <HttpsRedirect>
            <HelmetProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home property={property} isLoading={isLoading} contact={contact}/>}/>

                        <Route path="/apartments"
                               element={<Apartments property={property} isLoading={isLoading} contact={contact}/>}/>
                        <Route path="/floorplans/:floorplanId"
                               element={<FloorplanPage isLoading={isLoading} property={property} contact={contact}/>}/>

                        <Route path="/houses" element={<Houses/>}/>
                        <Route path="/estelle" element={<Estelle  property={property} isLoading={isLoading} contact={contact}/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </Router>
            </HelmetProvider>
        </HttpsRedirect>

    );
};

export default App;
