import React from 'react';
import './assets/ApartmentHeroSection.scss';

export const ApartmentHeroSection: React.FC = () => {
    return (
        <section className="section-apartments-hero">
            <div className="div-hero-image">

            </div>

            <h2>Apartments & Townhomes </h2>
            <h4>for rent</h4>
        </section>
    );
}