import React from 'react';
import logo from './assets/Logo.png';
import './assets/Logo.scss';

export const Logo: React.FC<LogoProps> = ({handleRefToTop, isHomePage}) => {
    return (
        <div className="logo">
            <a href={isHomePage ? "#home" : "/#home"} onClick={() => {
                if (handleRefToTop) handleRefToTop();
            }}>
                <img src={logo} alt="logo"/>
            </a>
        </div>
    )
};

export interface LogoProps {
    handleRefToTop: () => void;
    isHomePage: boolean;
}